const state = {
	// 存储token
	token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
}

const mutations = {
	// 修改token，并将token存入localStorage
	changeLogin (state, user) {
		state.token = user.token;
	    localStorage.setItem('token', user.token);
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
const state = {
  item: {
    name: "龙岩国家地质森林公园",
    details: "走进“江山景区”的石山园，但见幽幽青山，绿树成荫，老藤缠绕，瀑布飞流，鸟鸣山涧，竹海呼啸，游人到此，仿佛走进世外桃园。丞相岩、石观音、一线天、仙袋长啸、冲天蜡烛等大自然鬼斧神工的杰作，点缀于茂林修竹之间，令人称奇。 龙岩国家森林公园是一个不可多得的生物资源天然宝库，森林覆盖率达91%。这里植被保存相当完好，原始森林、天然阔叶林、次生常绿阔叶针叶混交林、中山草甸、竹林，层次分明；壳斗科、樟科、金缕梅科、木兰科、山茶科、木犀科、禾本科2600多种植物应有尽有；南方红豆杉、银杏、闽楠、桫椤、水杉等大量珍稀树种分布其间，有的甚至连绵成片，蔚为壮观。更有虎、豹、鹿、獐、猴、喜鹊、白鹇、红嘴兰鹊等走兽飞禽出没其中。",
	monitor_video: "ezopen://open.ys7.com/AC6127833/1.hd.live"
  },
  Arr: []
}

const mutations = {
  SET_ITEM: (state, item) => {
	  console.log(item,1111)
    state.item = item
  },
  SET_ARR: (state, item) => {
    state.Arr = item
  }
}
const actions = {
  setItem({
    commit
  }, item) {
    commit(`SET_ITEM`, item)
  },
  setArr({
    commit
  }, arr) {
    commit(`SET_ARR`, arr)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
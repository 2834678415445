const state = {
	iframeVR: false,
	iframeVideo: false,
	iframeImg: false,
	iframeAssets: false,
	VRSrc: '',
	VideoSrc: '',
	ImgSrc: '',
	AssetsList: []
}

const mutations = {
	SET_IFRAME_TYPE_TRUE: (state, item) => {
		state[item] = true
	},
	SET_IFRAME_TYPE_FALSE: (state, item) => {
		state[item] = false
	},
	SET_IFRAME_SRC: (state, item) => {
		//console.log(item.src,9999)
		if (item.name == `iframeVR`) {
			state.VRSrc = item.src
		} else if (item.name == `iframeVideo`) {
			state.VideoSrc = item.src
		} else {
			let img = '';
			if(!item.src.startsWith('$')) {
				if(item.src.startsWith('http')) {
					img = item.src;
				} else {
					if (JSON.parse(item.src)?.[0]) {
						img = `https://vlog1.ichuangnong.com/prod-api/${
							JSON.parse(item.src)[0].data
						}`;
					}
				}
			} else {
				img = 'https://vlog1.ichuangnong.com/apps/apis/file/get?id=' + item.src + '&appid=jiangshan';
			}
			//console.log(img,'-----img')
			state.ImgSrc = img
		}
	},
	SET_TABLE: (state, item) => {
		state.AssetsList = item.list;
	}
}
const actions = {
	setItemTrue({commit}, item) {
		commit(`SET_IFRAME_TYPE_TRUE`, item.name)
		commit(`SET_IFRAME_SRC`, item)
	},
	setItemFlase({commit}, item) {
		commit(`SET_IFRAME_TYPE_FALSE`, item)
	},
	setTable({commit}, item) {
		commit(`SET_IFRAME_TYPE_TRUE`, item.name)
		commit(`SET_TABLE`, item)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
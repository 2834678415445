const state = {
  item: {
    name: "村美精品民宿",
    area: "8",
    address: "龙岩市新罗区江山镇村美村",
    details: "村美精品民宿，规划面积：8亩，资金来源：财政补助，社会投资：100万，民房改造成乡村民宿"
  }

}

const mutations = {
  SET_ITEM: (state, item) => {
    state.item = item
  }
}
const actions = {
  setItem({
    commit
  }, item) {
    commit(`SET_ITEM`, item)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
const state = {
	item: {
		village_id: sessionStorage.getItem('village_id') ? sessionStorage.getItem('village_id') : '',
		village_name: sessionStorage.getItem('village_name') ? sessionStorage.getItem('village_name') : '',
		town_code: sessionStorage.getItem('town_code') ? sessionStorage.getItem('town_code') : 'TOM1667025818569',
		town_name: sessionStorage.getItem('town_name') ? sessionStorage.getItem('town_name') : '江山镇',
		city_id: sessionStorage.getItem('city_id') ? sessionStorage.getItem('city_id') : '350802',
		city_name: sessionStorage.getItem('city_name') ? sessionStorage.getItem('city_name') : '新罗区',
	},
	isShow: false,
	vrUrl: 'https://www.720yun.com/vr/b5ejOdkmtw1?s=5321218',
	videoUrl: 'https://oos-cn.ctyunapi.cn/supply-video/%E6%B1%9F%E5%B1%B1%E6%83%85MV2022.11.8%E5%B8%A6%E5%8F%B0%E6%A0%87.mp4',
}

const mutations = {
	SET_CITY: (state, item) => {
		console.log(item,'---cityitem')
		state.item.city_id = item.id;
		state.item.city_name = item.city_name;
		state.item.town_code = item.childList[0].id;
		state.item.town_name = item.childList[0].name;
		state.item.village_id = '';
		state.item.village_name = '';
		sessionStorage.setItem('city_id', item.id);
		sessionStorage.setItem('city_name', item.city_name);
		sessionStorage.setItem('town_code', item.childList[0].id);
		sessionStorage.setItem('town_name', item.childList[0].name);
		sessionStorage.setItem('village_id', '');
		sessionStorage.setItem('village_name', '');
	},
	SET_TOME: (state, item) => {
		console.log(item,'---townitem')
		state.item.town_code = item.town_id;
		state.item.town_name = item.town_name;
		state.item.village_id = '';
		state.item.village_name = '';
		sessionStorage.setItem('town_code', item.town_id);
		sessionStorage.setItem('town_name', item.town_name);
		sessionStorage.setItem('village_id', '');
		sessionStorage.setItem('village_name', '');
	},
	SET_VILLAGE: (state, item) => {
		console.log(item,'---vilageitem')
		state.item.village_id = item.village_id;
		state.item.village_name = item.village_name;
		sessionStorage.setItem('village_id', item.village_id);
		sessionStorage.setItem('village_name', item.village_name);
	},
	SET_OPEN_STATE: (state, item) => {
		state.isShow = item;
	},
	SET_VR_URL: (state, item) => {
		state.vrUrl = item;
	},
	SET_VIDEO_URL: (state, item) => {
		state.videoUrl = item;
	}
}
const actions = {
	setCity({commit},item){
		commit(`SET_CITY`,item);
		location.reload();
	},
	setTown({commit},item){
		commit(`SET_TOME`,item);
		location.reload();
	},
	setVillage({commit},item){
		commit(`SET_VILLAGE`,item);
		location.reload();
	},
	setOpenState({commit},item) {
		commit(`SET_OPEN_STATE`,item)
	},
	setVrUrl({commit},item) {
		commit(`SET_VR_URL`,item)
	},
	setVideo({commit},item) {
		commit(`SET_VIDEO_URL`,item)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/smartAgriculture',
	},
	{
		path: '/smartAgriculture',
		name: 'SmartAgriculture',
		component: () => import('@/views/smartAgriculture.vue'),
		meta: {
			title: '智慧农业数据大屏',
			needLogin: true
		}
	},
	{
		path: '/smartMap',
		name: 'SmartMap',
		component: () => import('@/views/smartMap.vue'),
		meta: {
			title: '智慧农场地图',
			needLogin: true
		}
	},
	{
		path: '/talents',
		name: 'talents',
		component: () => import('@/views/talents.vue'),
		meta: {
			title: '人才模块',
			needLogin: true
		}
	},
	{
		path: '/partyBuilding',
		name: 'partyBuilding',
		component: () => import('@/views/partyBuilding.vue'),
		meta: {
			title: '党员模块',
			needLogin: true
		}
	},
	{
		path: '/farming',
		name: 'farming',
		component: () => import('@/views/farming.vue'),
		meta: {
			title: '农业产业板块',
			needLogin: true
		}
	},
	{
		path: '/culturalTourism',
		name: 'culturalTourism',
		component: () => import('@/views/culturalTourism.vue'),
		meta: {
			title: '文旅板块',
			needLogin: true
		}
	},
	{
		path: '/invest',
		name: 'invest',
		component: () => import('@/views/invest.vue'),
		meta: {
			title: '招商板块',
			needLogin: true
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue'),
		meta: {
			title: '登录'
		}
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	let matched = to.matched.some((item) => {
		return item.meta.needLogin;
	});
	let token = localStorage.getItem('token');
	
	if (matched) { // 判断是否需要登录权限
		// 判断是否有token
		if(token != null && token != '') {
			next();
		} else {
			next('/login')
		}
	} else {
		next()
	}
})

export default router

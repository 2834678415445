const state = {
  longitude: 116.985248,
  latitude: 25.183142,
  item: {},
  ListArr: [],
  pointType: 'all'
}

const mutations = {
  SET_CENTER: (state, item) => {
    state.longitude = item.longitude
    state.latitude = item.latitude
  },
  SET_ARR: (state, item) => {
    state.ListArr = item
  },
  SET_ITEM: (state, item) => {
    state.item = item
    console.log(state.item)
  },
  SET_TYPE: (state, item) => {
    state.pointType = item;
  },
}
const actions = {
  setCenter({
    commit
  }, item) {
    commit(`SET_CENTER`, item)
    commit(`SET_ITEM`, item)
  },
  setArr({
    commit
  }, item) {
    commit(`SET_ARR`, item)
  },
  setType({
    commit
  }, item) {
    commit(`SET_TYPE`, item)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
const getters = {
  farming: state => state.app.farming,
  travel: state => state.app.travel,
  invest: state => state.app.invest,
  partPeople: state => state.app.partPeople,
  talents: state => state.app.talents,
  showIframe: state => state.app.showIframe,
  map:state=>state.app.map,
  region:state=>state.app.region,
  introduct: state => state.app.introduct
}
export default getters
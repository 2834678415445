import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import {
//   initGlobalState
// } from 'qiankun';
// Vue.use(Vuex)
// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
// const actions = initGlobalState(modules.columns);
// // 父应用监听store变化
// actions.onGlobalStateChange((state, prev) => {
//   // state: 变更后的状态; prev 变更前的状态
//   // console.log('app state', state);
//   // console.log('app prev', prev);
// });

// const store = new Vuex.Store({
//   modules,
//   getters
// })

// export default store
Vue.use(Vuex)
let hasCondition = (state, formId, needIndex = false) => {
  let index = state.processConditions.findIndex(d => d.formId === formId)
  return needIndex ? index : index > -1
}
const store = new Vuex.Store({
  modules,
  getters,
  mutations: {
    //所有mutations中的方法的第一个参数一定是state变量，用来进行对state中的状态的操作
    //第二个参数是可选参数，用于调用该 mutations 方法的时候传参
    
  }
})

export default store

const state = {
  item: {
    name: "温基旺",
    sex: "男",
    date_of_birth: "1973-03-17",
    img: "",
    party_membership_time: "1997-12-10"  ,
    in_department: `中共江山镇背洋村支部委员会`,
    now_add: `新罗区江山镇背洋村洋底路10号`
  }
}

const mutations = {
  SET_ITEM: (state, item) => {
    // if (item.img == "[]") {
    //   console.log(`头像`)
    //   item.img = "[{\"name\":\"温基旺.jpg\",\"data\":\"/file/202304/50b6c944b9444fb797ace00bafdc4258.jpg\",\"uid\":1680599434775,\"status\":\"success\"}]"
    // }
    state.item = item
  }
}
const actions = {
  setItem({
    commit
  }, item) {
    commit(`SET_ITEM`, item)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
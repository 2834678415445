const state = {
  item: {
    img: "",
    is_party: "1",
    latitude: "25.324944654094843",
    longitude: "116.94099780136591",
    most_school: "龙岩电大",
    name: "温基旺",
    native_place: "福建龙岩",
    now_add: "新罗区江山镇背洋村洋底路10号",
    now_work_address: "江山镇背洋村大罗坂水电站",
    sex: "男",
  }
}

const mutations = {
  SET_ITEM: (state, item) => {
    // if (item.img == "[]") {
    //   item.img = "[{\"name\":\"温基旺.jpg\",\"data\":\"/file/202304/50b6c944b9444fb797ace00bafdc4258.jpg\",\"uid\":1680599434775,\"status\":\"success\"}]"
    // }
    state.item = item
  }
}
const actions = {
  setItem({
    commit
  }, item) {
    commit(`SET_ITEM`, item)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
const state = {
  item: {
    name: `三禾农业科技有限公司`,
    details: "联系人：郭盛，种植品种：大花惠兰，种植规模：15亩，种植地址：龙岩市新罗区江山镇铜砵村"
  }

}

const mutations = {
  SET_ITEM: (state, item) => {
    state.item = item
  }
}
const actions = {
  setItem({commit},item){
    commit(`SET_ITEM`,item)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
const state = {
	title: '人文历史概览',
	// first_content: '江山镇地处龙岩市新罗区西北部，国家A级自然保护区梅花山南麓，是龙岩国家森林公园的重要组成部分。距龙岩城区11公里，全镇总人口1.19万人，党员707人，面积248.5平方公里，耕地1.44万亩，林地32万亩，下辖16个行政村，67个自然村，22个革命基点村，平均海拔830米。以环境优美、山水奇特而著称，其得天独厚的旅游资源有“龙岩、武夷出江山，江山龙岩之鼓山”之说，是一个投资开发前景广阔的旅游圣地，镇内青山绿水蓝天，风景独特，气候宜人，生态环境极好，是典型的农业生态乡镇，文化旅游乡镇，“龙岩城市后花园”，素有“小武夷”美称。目前为国家级生态镇、中国绿色名镇、省级文明镇、省级宣传示范乡镇。',
	// sec_content: '近年来，在上级党委、政府的正确领导下，立足自身优势，继续以创建省文明乡镇为目标，实施“三路•五大品牌”发展战略，走上“文化康养”特色小镇的发展道路，不断强化思想引领、提升群众素质、培育文明新风，为加快全镇经济社会跨越发展提供了强有力的精神动力和智力支持。2015年被省委省政府授予“省文明镇”，山头村、新寨村被授予“市级文明村”称号，2016年科山、前村、新田、福坑、老寨、山塘等6个村，被授予“区级文明村”；江山中心小学、江山中学，被授予“区级文明学校”；江山卫生院被授予“区级文明单位”。'
	intro_content: '<p>江山镇地处龙岩市新罗区西北部，国家A级自然保护区梅花山南麓，是龙岩国家森林公园的重要组成部分。距龙岩城区11公里，全镇总人口1.19万人，党员707人，面积248.5平方公里，耕地1.44万亩，林地32万亩，下辖16个行政村，67个自然村，22个革命基点村，平均海拔830米。以环境优美、山水奇特而著称，其得天独厚的旅游资源有“龙岩、武夷出江山，江山龙岩之鼓山”之说，是一个投资开发前景广阔的旅游圣地，镇内青山绿水蓝天，风景独特，气候宜人，生态环境极好，是典型的农业生态乡镇，文化旅游乡镇，“龙岩城市后花园”，素有“小武夷”美称。目前为国家级生态镇、中国绿色名镇、省级文明镇、省级宣传示范乡镇。</p><p>近年来，在上级党委、政府的正确领导下，立足自身优势，继续以创建省文明乡镇为目标，实施“三路•五大品牌”发展战略，走上“文化康养”特色小镇的发展道路，不断强化思想引领、提升群众素质、培育文明新风，为加快全镇经济社会跨越发展提供了强有力的精神动力和智力支持。2015年被省委省政府授予“省文明镇”，山头村、新寨村被授予“市级文明村”称号，2016年科山、前村、新田、福坑、老寨、山塘等6个村，被授予“区级文明村”；江山中心小学、江山中学，被授予“区级文明学校”；江山卫生院被授予“区级文明单位”。</p>'
}

const mutations = {
	SET_REGION_INTRO: (state, item) => {
		state.intro_content = item;
	},
}

const actions = {
	setRegionIntro({commit},item){
		commit(`SET_REGION_INTRO`,item);
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
<template>
  <scale-box>
    <div id="app">
      <router-view />
    </div>
  </scale-box>
</template>

<script>
import ScaleBox from "@/components/ScaleBox/ScaleBox";
export default {
  name: "App",
  components: {
    ScaleBox,
  },
};
</script>
<style lang="scss">
@import "./assets/css/index.scss";
</style>

<style scoped lang="scss">
#app {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "mao"; /* 替换为自定义字体的名称 */
  src: url("~@/assets/font/mao.ttf") format("truetype"); /* 替换为字体文件的路径 */
  /* 可选：定义其他字体属性，如font-weight、font-style等 */
}

@font-face {
  font-family: "szc"; /* 替换为自定义字体的名称 */
  src: url("~@/assets/font/szc.ttf") format("truetype"); /* 替换为字体文件的路径 */
  /* 可选：定义其他字体属性，如font-weight、font-style等 */
}

</style>
